import { ORDER, SORT } from "~/constants";
import { SEGMENT_TRACK_EVENTS } from "~/constants/segment";
import useRequest from "~/hooks/use-request";
import CategoriesAPI from "~/services/categories";
import useUserStore from "~/stores/user";
import { formatModelFollowedItem, formatResponseFollowedItem, returnPaginatedResponse } from "./format";

const FollowAPI = {
  load(options?: IRequestPaginatedParams & { types?: TFollowType }): Promise<IModelFollowedItem[]> {
    const { request } = useRequest();
    const userStore = useUserStore();

    const defaultParams = {
      size: 150,
      version: 2,
    };

    const params = Object.assign({}, defaultParams, options);

    return request
      .get<IPaginatedResponse<IResponseFollowedItem<IResponseFollowedItemEntity>>>("/api/follow/list_by_user", { query: params })
      .then((response) => {
        userStore.followed = {};
        const followedItems = response.content.entries.map(formatResponseFollowedItem);
        followedItems.forEach(item => userStore.followed[item.id] = item);

        return followedItems;
      });
  },

  loadCache(options: IRequestPaginatedOptions & { identityId: string }): Promise<IPaginatedResponse<IModelFollowedItem>> {
    const userStore = useUserStore();
    const followedIdentityItems = userStore.followedItems;

    if (options.identityId) {
      return Promise.resolve(
        returnPaginatedResponse(
          followedIdentityItems.filter(item => item.identitiesId?.includes(options.identityId)),
        ),
      );
    }

    return Promise.resolve(returnPaginatedResponse(followedIdentityItems));
  },

  async loadGroupedByType(): Promise<IModelFollowedItemGroupedByType[]> {
    const userStore = useUserStore();

    const identities = await CategoriesAPI.load({ page: 0, pageSize: 50, order: ORDER.asc, sort: SORT.order }).then(response => response.content.entries);
    const groupedItems = userStore.followedIdentitiesIds.reduce((acc: IModelFollowedItemGroupedByType[], curr) => {
      const title = identities.find(c => c.id === curr)?.title;
      if (!title)
        return acc;

      const item = { id: curr, title, followedItems: userStore.followedItems.filter(s => s.identitiesId?.includes(curr)) };

      return [...acc, item];
    }, []);
    return groupedItems;
  },

  follow(options: { item: TFollowItemType; type: TFollowType }): Promise<void> {
    const segment = useSegment();
    const { request } = useRequest();
    const { item, type } = options;
    const params = { reference_id: item.id, type };
    const userStore = useUserStore();
    const followItem = formatModelFollowedItem(item, type);
    const trackEvent = type === "team" ? SEGMENT_TRACK_EVENTS.TEAM_FOLLOWED : SEGMENT_TRACK_EVENTS.CATEGORY_FOLLOWED;
    const trackPayload = type === "team" ? { team_id: item.id, team_name: item.title } : { category_id: item.id, category_name: item.title };

    return request
      .post("/api/follow/save", { body: params })
      .then(() => {
        segment.track(trackEvent, trackPayload);
        userStore.followed[followItem.id] = followItem;
      });
  },

  unfollow(options: { item: TFollowItemType; type: TFollowType }): Promise<void> {
    const segment = useSegment();
    const { request } = useRequest();
    const { item, type } = options;
    const params = { id: item.id };
    const userStore = useUserStore();

    const trackEvent = type === "team" ? SEGMENT_TRACK_EVENTS.TEAM_UNFOLLOWED : SEGMENT_TRACK_EVENTS.CATEGORY_UNFOLLOWED;
    const trackPayload = type === "team" ? { team_id: item.id, team_name: item.title } : { category_id: item.id, category_name: item.title };

    return request
      .post("/api/follow/remove_by_reference", { body: params })
      .then(() => {
        segment.track(trackEvent, trackPayload);
        delete userStore.followed[item.id];
      });
  },

};

export default FollowAPI;
